import { FC, useState } from "react";

//Import components:
import {
  Box,
  Button,
  Container,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { FirstStep } from "../../components/OnboardingComponents/FirstStep";
import { SecondStep } from "../../components/OnboardingComponents/SecondStep";
import MobileStepper from "@mui/material/MobileStepper";

//Import utils:
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

type Props = {
  className?: string;
};

export const Onboarding: FC<Props> = (props) => {
  const { className } = props;

  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();

  const [t, i18n] = useTranslation("translation");

  const [language, setLanguage] = useState(
    t("langChange.select") === "Selectează limba" ? "RO" : "EN",
  );

  //menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeLanguage = (lang: string) => {
    if (lang === "ro") {
      i18n.changeLanguage("ro");
      setLanguage("RO");
    } else if (lang === "en") {
      setLanguage("EN");
      i18n.changeLanguage("en");
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
  };

  const handleGoToHome = () => {
    navigate("/home");
  };

  const [isVisible, setIsVisible] = useState(true);
  setTimeout(() => {
    setIsVisible(false);
  }, 3500);

  return (
    <Box
      className={`${className}-Onboarding`}
      sx={{
        filter: open ? "blur(3px)" : "",
      }}
    >
      <Container>
        {isVisible ? (
          <Typography className="Onboarding__title">
            <div dangerouslySetInnerHTML={{ __html: t("title") }} />
          </Typography>
        ) : (
          <>
            {activeStep === 0 && <FirstStep />}
            {activeStep === 1 && <SecondStep />}

            <MobileStepper
              className="Onboarding__stepper"
              variant="dots"
              steps={2}
              position="static"
              activeStep={activeStep}
              sx={{
                background: "none",
                "& .MuiMobileStepper-dot": {
                  backgroundColor: "transparent",
                },
                "& .MuiMobileStepper-dotActive": {
                  backgroundColor: "transparent",
                },
              }}
              nextButton={
                <Button
                  className="Onboarding__button"
                  onClick={activeStep === 1 ? handleGoToHome : handleNext}
                >
                  {activeStep === 0 ? (
                    <Typography className="Onboarding__button--text">
                      {t("next")}
                    </Typography>
                  ) : (
                    <Typography className="Onboarding__button--text">
                      {t("getStarted")}
                    </Typography>
                  )}
                </Button>
              }
              backButton={<></>}
            />

            <div className="Onboarding__language">
              <Typography
                style={{ marginTop: "7px" }}
                className="Onboarding__language--text"
              >
                {t("langChange.select")}
              </Typography>

              <Button
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                disableElevation
                onClick={handleClick}
                className="Onboarding__language--button"
              >
                <Typography className="Onboarding__language--text">
                  {language}
                </Typography>
              </Button>

              <Menu
                elevation={0}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    background: "rgba(255, 255, 255, 0.25)",
                    borderRadius: "8px",
                    border: "1px solid #48484A",
                    width: "325px",
                    marginTop: "-60px",
                  },
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleChangeLanguage("en");
                    handleClose();
                  }}
                  disableRipple
                >
                  <Typography className={`${className}-Onboarding--text`}>
                    {t("langChange.english")}
                  </Typography>
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    handleChangeLanguage("ro");
                    handleClose();
                  }}
                  disableRipple
                >
                  <Typography className={`${className}-Onboarding--text`}>
                    {t("langChange.romanian")}
                  </Typography>
                </MenuItem>
              </Menu>
            </div>
          </>
        )}
      </Container>
        
    </Box>
  );
};
