import React from 'react';
import ReactDOM from 'react-dom/client';
import App from "./routes/index";
import reportWebVitals from './reportWebVitals';
import "./global.css";
import "./fonts/Nunito/Nunito-VariableFont_wght.ttf";
import { I18nextProvider } from 'react-i18next';
import i18n from '../src/translations/i18n';
import { GlobalProvider } from './context/context';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <GlobalProvider>
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>
  </GlobalProvider>,

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
