import { styled } from "@mui/material";
import { Home } from "./Home";

const Styled = styled(Home)`
  &-Home {
    &--text {
      color: #48484a;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: none;
    }
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;

    align-items: center;
    background: #fdeac4;
    &&& {
      .Home {
        &__title {
          color: #524e70;
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: 44px;
          margin-bottom: 30px;
        }

        &__header {
          align-items: center;
          text-align: center;
          display: flex;
          justify-content: space-between;
          border-radius: 8px;
          background: rgba(7, 4, 47, 0.1);
          box-shadow: 4px 4px 18px 0px rgba(255, 255, 255, 0.15);
          backdrop-filter: blur(66.5px);
          width: 100%;
          width: -moz-available;          /* WebKit-based browsers will ignore this. */
          width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
          width: fill-available;
          margin: 20px;
          padding: 16px;
          &--text {
            color: #48484a;
            text-align: center;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            text-transform: uppercase;
            margin: 0px auto;
          }
        }

        &__subheader {
          color: #48484a;
          text-align: center;
          margin-bottom: 10px;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }

        &__text {
          color: #030022;
          text-align: center;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0.12px;
        }

        &__button {
          position: absolute;
          left: 0;
          bottom: 0;
          margin: 20px;
          margin-top: 35px;
          text-transform: none;
          border-radius: 25px;
          background: rgba(255, 255, 255, 0.1);
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          display: inline-flex;
          height: 40px;
          padding: 16px 24px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          flex-shrink: 0;
          &--text {
            color: #48484a;
            text-align: center;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }
        &__possibilities {
          position: absolute;
          right: 0;
          bottom: 0;
          margin: 20px;
          display: flex;
          justify-content: space-between;
          gap: 10px;
          margin-top: 35px;
          &--text {
            color: #48484a;
            text-align: center;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-left: 10px;
          }

          &--button {
            display: flex;
            height: 40px;
            justify-content: center;
            align-items: center;
            border-radius: 68px;
            background: rgba(255, 255, 255, 0.1);
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            text-transform: none;
          }
        }

        &__next {
          position: absolute;
          right: 0;
        }

        &__back {
          position: absolute;
          left: 0;
        }

        &__square {
          margin: 0 auto;
          display: block;
          display: flex;
          width: 85px;
          height: 85px;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          border-radius: 20px;
          background: transparent;
          &__image {
            height: inherit;
            width: 100%;
            width: -moz-available;          /* WebKit-based browsers will ignore this. */
            width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
            width: fill-available;
            object-fit: fill;
            height: 100%;
            border-radius: 20px;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          }
        }
      }
    }
  }
`;

export { Styled };
