import { FC } from "react";

//Import components:
import { Box, Button, IconButton, Typography } from "@mui/material";

//Import utils:
import { Link, useNavigate } from "react-router-dom";
import {useTranslation} from "react-i18next";

//Import assets:
import { ReactComponent as CloseIcon } from "../../assets/close-icon.svg";
import Sticker from "../../assets/sticker.png";

type Props = {
  className?: string;
};

export const AboutUs: FC<Props> = (props) => {
  const { className } = props;
  const {t} = useTranslation('translation');
  const navigate = useNavigate();

  return (
    <Box className={`${className}-AboutUs`}>
      <div className="AboutUs__header">
        <Typography className="AboutUs__header--text">{t('aboutUs.title')}</Typography>
        <IconButton
          className="AboutUs__header--button"
          onClick={() => navigate(-1)}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <Box
        className="AboutUs__container"
        style={{width:"85%",
         alignItems:"center", justifyContent:"center", display:"flex", flexDirection:"column"}}
      >
        <Typography className="AboutUs__text">
        <div dangerouslySetInnerHTML={{ __html: t('aboutUs.subtitle') }} />
        </Typography>
         <img src={Sticker} className="AboutUs__rectangle"/>
        <Typography className="AboutUs__text">
        {t('aboutUs.welcome')}
        </Typography>
        <img src={Sticker} className="AboutUs__rectangle"/>
        <Typography className="AboutUs__text">
          <b>{t('aboutUs.concept.title')}</b> <br />
          {t('aboutUs.concept.text')}
        </Typography>

        <img src={Sticker} className="AboutUs__rectangle"/>
        <Typography className="AboutUs__text">
        <b>{t('aboutUs.interaction.title')}</b> <br />
          {t('aboutUs.interaction.text')}
        </Typography>
        <img src={Sticker} className="AboutUs__rectangle"/>
        <Typography className="AboutUs__text">
        <b>{t('aboutUs.exp.title')}</b> <br />
          {t('aboutUs.exp.text')}
        </Typography>
        <img src={Sticker} className="AboutUs__rectangle"/>
        <Typography className="AboutUs__text">
        <b>{t('aboutUs.future.title')}</b> <br />
          {t('aboutUs.future.text')}
        </Typography>
        <img src={Sticker} className="AboutUs__rectangle"/>
        <Typography className="AboutUs__text">
        <b>{t('aboutUs.change.title')}</b> <br />
          {t('aboutUs.change.text')}
        </Typography>
      </Box>
      <Link className="AboutUs__text-simple" to={'https://www.schoolofpossibilities.org/'}>{t("aboutUs.link")}</Link>
    </Box>
  );
};
