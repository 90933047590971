import {
  bottomNavigationActionClasses,
  bottomNavigationClasses,
  styled,
} from "@mui/material";
import { ChatBubble } from "./ChatBubble";

const Styled = styled(ChatBubble)`
  &-ChatBubble {
    margin-bottom: 20px;
    &&& {
      .ChatBubble {
        &__message {
          font-size: 14px;
          font-weight: 400;
          line-height: normal;
          word-wrap: break-word;
          font-family: Poppins;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        &__time {
          color: rgba(0, 0, 0, 0.6);

          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        &__receiver-icon {
          align-self: flex-end;
          border-radius: 61px;
          background: rgba(255, 255, 255, 0.4);

          box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.1);
          display: flex;
          width: 25px;
          height: 25px;
          justify-content: center;
          align-items: center;
          padding: 5px;
        }

        &__artefact-icon {
          margin: 0 auto;
          display: block;
          display: flex;
          width: 73px;
          height: 73px;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          border-radius: 20px;
          background: transparent;
          margin-right: 3px;
          &__image {
            height: inherit;
            width: 100%;
            width: -moz-available;          /* WebKit-based browsers will ignore this. */
            width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
            width: fill-available;
            object-fit: fill;
            height: 100%;
            border-radius: 20px;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          }
        }

        &__column {
          display: flex;
          flex-direction: column;
        }

        &__artefact-text {
          margin-top: 10px;
          text-align: center;
          display: flex;
          align-self: center;
          word-wrap: break-word;
          max-width: fit-content;
        }

        &__artefact-text-variation {
          margin-top: 10px;
          text-align: center;
        }

        &__sender-icon {
          align-self: flex-end;
          border-radius: 61px;
          background: rgba(255, 255, 255, 0.4);

          /* Shadow */
          box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.1);
          display: flex;
          width: 20px;
          height: 20px;
          justify-content: center;
          align-items: center;
          padding: 7px;
          margin-left: 5px;
        }

        &__title {
          color: black;
          font-size: 13px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          position: relative;
          top: -45px;
          &--sender {
            left: 80%;
            align-self: flex-end;
            margin-right: 0;
          }
        }

        &__chat {
          padding: 10px;
          margin: 5px;
          max-width: 70%;
          word-wrap: break-word;
          &__sender {
            color: white;
            align-self: flex-end;
            margin-left: auto;
            margin-right: 0;
            padding: 12px;
            border-radius: 8px;
            background: rgba(243, 243, 243, 0.4);
            box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.1);
          }

          &__receiver {
            border-radius: 8px;
            background: #fff;
            box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.1);
            display: flex;
            padding: 12px 28px 12px 12px;
            align-items: center;
            gap: 10px;
            height: fit-content;
            width: fit-content;
            word-wrap: break-word;
          }
        }
      }
    }
  }
`;

export { Styled };
