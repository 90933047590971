import { createTheme } from '@mui/material';

export const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Inter',
      fontSize: '13px',
      lineHeight: '18px',
      userSelect: 'none'
    },
    htmlFontSize: 13,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        fontFamily: 'Inter',
        fontSize: '13px',
        lineHeight: '18px',

        body: {
          fontFamily: 'Inter',
          fontSize: '13px',
          lineHeight: '18px',
          background: '#F6F6F6',
          overflow: 'hidden',
          height: '100vh',
          width: '100vw',
          '#root': {
            height: '100%',
          },
        },
      },
    },
  },
});