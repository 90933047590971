import { styled } from "@mui/material";
import { FirstStep } from "./FirstStep";

const Styled = styled(FirstStep)`
  &-FirstStep {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;
    &&& {
      .FirstStep {
        &__subtitle {
          color: #48484A;
          font-family: Inter;
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: 44px;
          margin-top: 23px;
          text-align: center;
        }
        &__text {
          color: #48484A;
          font-size: 16px;
          font-family: Poppins;
          font-style: normal;
          font-weight: 300;
          line-height: 24px;
          margin-top: 21px;
          text-align: center;
          margin-bottom: 20px;
        }
      }
    }
  }
`;

export { Styled };
