// GlobalContext.tsx
import React, { createContext, useState, useContext, ReactNode } from 'react';

interface GlobalContextType {
    globalAuthVariable: boolean;
    setGlobalAuthVariable: (value: boolean) => void;
}

const GlobalContext = createContext<GlobalContextType | undefined>(undefined);

export const useGlobalContext = (): GlobalContextType => {
    const context = useContext(GlobalContext);
    if (!context) {
        throw new Error('useGlobalContext must be used within a GlobalProvider');
    }
    return context;
};

interface GlobalProviderProps {
    children: ReactNode;
}

export const GlobalProvider: React.FC<GlobalProviderProps> = ({ children }) => {
    const [globalAuthVariable, setGlobalAuthVariable] = useState<boolean>(false);

    return (
        <GlobalContext.Provider value={{ globalAuthVariable, setGlobalAuthVariable }}>
            {children}
        </GlobalContext.Provider>
    );
};
