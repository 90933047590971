import { styled } from "@mui/material";
import { LangModal } from "./LangModal";

const Styled = styled(LangModal)`
  &-LangModal {
    &&& {
      .LangModal {
        &__box {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 50%;
          border: 2px solid #fff;
          box-shadow: 24;
          border-radius: 8px;
          background: #fff;
          padding: 10px;
          &__text {
            color: #48484a;
            text-align: center;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
            margin-bottom: 15px;
          }
          &__row {
            /* display: flex;
    justify-content: space-between;
    flex-direction: row; */
          }
          &__button {
            margin: 10px;
            padding: 10px;
            text-transform: none;
            border-radius: 25px;
            background: #fdeac4;
            box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.1);
            display: inline-flex;
            height: 40px;
            padding: 16px 24px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex-shrink: 0;
            &__text {
              color: #48484a;
              text-align: center;
              font-family: Poppins;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }
        }
      }
    }
  }
`;

export { Styled };
