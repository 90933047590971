import { styled } from "@mui/material";
import { AboutUs } from "./AboutUs";

const Styled = styled(AboutUs)`
  &-AboutUs {
    &--text {
      color: #48484A;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: none;
    }
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;

    align-items: center;
    background: #FDEAC4;
    &&& {
      .AboutUs {
        &__header {
          align-items: center;
          text-align: center;
          display: flex;
          justify-content: space-between;
          border-radius: 8px;
          background: rgba(7, 4, 47, 0.1);
          box-shadow: 4px 4px 18px 0px rgba(255, 255, 255, 0.15);
          backdrop-filter: blur(66.5px);
          width: 100%;
          width: -moz-available;          /* WebKit-based browsers will ignore this. */
          width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
          width: fill-available;
          margin: 20px;
          padding: 16px;
          &--text {
            color: #48484A;
            text-align: center;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 133.333% */
            text-transform: uppercase;
            margin: 0px auto;
          }

          &--button {
            background-color: transparent;
            border-radius: 50px;
            border: 2px solid #c93030;
          }
        }

        &__text {
          color: #48484A;
          text-align: center;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 171.429% */
          margin-top: 60px;
          margin-bottom: 60px;
        }
        &__text-simple {
          color: #48484A;
          text-align: center;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 171.429% */
          margin-bottom: 10px;
        }
        &__rectangle {
          border-radius: 8px;
          background: rgba(255, 255, 255, 0.4);
          box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.1);
          width: 160px;
          height: 160px;
          margin: 0 auto;
        }

        &__container {
          overflow-y: scroll;
          overflow-x: hidden;
          height: 100vh;
          width: 80%;
          padding: 20px;
          width: 85%;
          border-radius: 16px;
          background: rgba(255, 255, 255, 0.4);
          margin-bottom: 20px;
        }
      }
    }
  }
`;

export { Styled };
