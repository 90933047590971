import { FC, useState } from "react";

//Import components:
import {
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  MobileStepper,
  SvgIcon,
  Typography,
} from "@mui/material";

//Import utils:
import { artefacts } from "../../utils/artefacts";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

//Import assets:
import { ReactComponent as ArrowRight } from "../../assets/arrow_right.svg";
import { ReactComponent as ArrowLeft } from "../../assets/arrow_left.svg";
import { ReactComponent as Possibilities } from "../../assets/possibilities-icon.svg";

type Props = {
  className?: string;
};

export const Home: FC<Props> = (props) => {
  const { className } = props;

  const artefactsList = artefacts;
  const navigate = useNavigate();
  const [t] = useTranslation("translation");

  const handleGoToArtefacts = (id: number) => {
    const artefact = artefactsList.find((i) => i.id === id);
    navigate("/gallery", {
      state: { id: artefact?.id, artefact: artefact?.url },
    });
  };

  const [activeStep, setActiveStep] = useState(0);
  const [language, setLanguage] = useState(
    t("langChange.select") === "Selectează limba" ? "RO" : "EN",
  );

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNavigateWebsite = () => {
    window.location.href = "https://www.schoolofpossibilities.org/";
  };

  return (
    <Box className={`${className}-Home`}>
      <div className="Home__header">
        <Typography className="Home__header--text">
          {t("home.title")}
        </Typography>
      </div>
      <Typography className="Home__subheader">{t("home.click")}</Typography>
      <Grid
        container
        spacing={1}
        style={{
          overflowX: "auto",
          height: "57vh",
          marginBottom: "20px",
        }}
      >
        {artefactsList.map((artefact, index) => {
          if (index >= activeStep * 9 && index < (activeStep + 1) * 9)
            return (
              <Grid
                key={index}
                item
                spacing={1}
                container
                xs={4}
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <div
                    className="Home__square"
                    onClick={() => handleGoToArtefacts(artefact.id)}
                  >
                    <img className="Home__square__image" src={artefact.icon} />
                  </div>
                </Grid>
                <Grid item>
                  <Typography className="Home__text">
                    {t(`artefacts.${artefact.agentId}.name`)}
                  </Typography>
                </Grid>
              </Grid>
            );
        })}
      </Grid>
      <MobileStepper
        className="Home__stepper"
        variant="dots"
        steps={Math.round(artefactsList.length / 9) + 1}
        position="static"
        activeStep={activeStep}
        sx={{
          background: "none",
          "& .MuiMobileStepper-dot": {
            backgroundColor: "#BBBBBB",
            opacity: "0.6",
            marginRight: "10px",
          },
          "& .MuiMobileStepper-dotActive": {
            backgroundColor: "black",
            borderRadius: "50px",
          },
        }}
        nextButton={
          <IconButton
            className="Home__next"
            onClick={handleNext}
            disabled={activeStep === Math.round(artefactsList.length / 9)}
          >
            <SvgIcon>
              <ArrowRight />
            </SvgIcon>
          </IconButton>
        }
        backButton={
          <IconButton
            className="Home__back"
            onClick={handleBack}
            disabled={activeStep === 0}
          >
            <SvgIcon>
              <ArrowLeft />
            </SvgIcon>
          </IconButton>
        }
      />
      <Button className="Home__button" onClick={() => navigate("/aboutus")}>
        <Typography className="Home__button--text">
          {t("aboutUs.title")}
        </Typography>
      </Button>
      <Button
        className="Home__button"
        style={{ alignSelf: "center", position: "relative" }}
        onClick={handleNavigateWebsite}
      >
        <Typography className="Home__button--text">
          {t("goTo")}
        </Typography>
      </Button>
      <div className="Home__possibilities">
        <Button
          onClick={() => navigate("/possibilities")}
          className="Home__possibilities--button"
        >
          <Possibilities />
          <Typography className="Home__possibilities--text">
            {t("possibilities")}
          </Typography>
        </Button>
      </div>
    </Box>
  );
};
