import { FC, useState } from "react";

//Import components:
import { Box, Button, TextField, Typography } from "@mui/material";

//Import utils:
import { tokens } from "../../utils/tokens";
import { useLocation, useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../context/context";

type Props = {
  className?: string;
};

export const TokenAuth: FC<Props> = (props) => {
  const { className } = props;
  const { globalAuthVariable, setGlobalAuthVariable } = useGlobalContext();

  const tokensList = tokens;
  const navigate = useNavigate();

  const [input, setInput] = useState("");
  const [error, setError] = useState(false);

  const handleClick = () => {
    const found = tokensList.findIndex(
      (token) => token.token.trim() === input.trim(),
    );
    if (found > -1) {
      setError(false);
      setGlobalAuthVariable(true);
      navigate("/");
    } else setError(true);
  };

  return (
    <Box className={`${className}-TokenAuth`}>
      <div className="TokenAuth__header">
        <Typography className="TokenAuth__header--text">
          School of Possibilities
        </Typography>
      </div>
      <Typography className="TokenAuth__subheader">
        Please enter a valid token:
      </Typography>

      <TextField
        error={error}
        label={error ? "Wrong token" : ""}
        value={input}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setInput(event.target.value);
        }}
      ></TextField>
      <Button className="TokenAuth__button" onClick={handleClick}>
        <Typography className="TokenAuth__button__text">Confirm</Typography>
      </Button>
    </Box>
  );
};
