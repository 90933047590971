import { FC } from "react";

//Import components:
import { Box, Typography } from "@mui/material";

//Import utils:
import { useTranslation } from "react-i18next";

type Props = {
  className?: string;
};

export const FirstStep: FC<Props> = (props) => {
  const { className } = props;
  const {t} = useTranslation("translation");

  return (
    <Box className={`${className}-FirstStep`}>
      <Typography className="FirstStep__subtitle">{t('welcome.title')}</Typography>
      <Typography className="FirstStep__text">
      {t('welcome.text')}
      </Typography>
       
    </Box>
  );
};
