import { styled } from "@mui/material";
import { TokenAuth } from "./TokenAuth";

const Styled = styled(TokenAuth)`
  &-TokenAuth {
    justify-content: center;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
    background: #fdeac4;
    &&& {
      .TokenAuth {
        &__title {
          color: #524e70;
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: 44px;
          margin-bottom: 30px;
        }

        &__header {
          align-items: center;
          text-align: center;
          display: flex;
          justify-content: space-between;
          border-radius: 8px;
          background: rgba(7, 4, 47, 0.1);
          box-shadow: 4px 4px 18px 0px rgba(255, 255, 255, 0.15);
          backdrop-filter: blur(66.5px);
          width: 50% !important;
          width: -moz-available; /* WebKit-based browsers will ignore this. */
          width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
          width: fill-available;
          margin: 20px;
          padding: 16px;
          &--text {
            color: #48484a;
            text-align: center;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            text-transform: uppercase;
            margin: 0px auto;
          }
        }

        &__subheader {
          color: #48484a;
          text-align: center;
          margin-bottom: 10px;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }

        &__button {
          margin: 10px;
          padding: 10px;
          text-transform: none;
          border-radius: 25px;
          background: rgba(7, 4, 47, 0.1);
          box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.1);
          display: inline-flex;
          height: 40px;
          padding: 16px 24px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          flex-shrink: 0;
          &__text {
            color: #48484a;
            text-align: center;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
      }
    }
  }
`;

export { Styled };
