//Import icons:
import Teacher from "../assets/teacher.jpg";
import Test from "../assets/test.jpg";
import Schoolmate from "../assets/schoolmate.jpg";
import Schoolbell from "../assets/schoolbell.jpg";
import Principal from "../assets/principal.jpg";
import Notebook from "../assets/notebook.jpg";
import Map from "../assets/map.jpg";
import BreadMilk from "../assets/breadandmilk.jpg";
import BulletinBoard from "../assets/bulletin-board.jpg";
import Baccalaureate from "../assets/baccalaureate.jpg";
import Playground from "../assets/playground.jpg";
import Whiteboard from "../assets/whiteboard.jpg";
import Textbooks from "../assets/textbooks.jpg";
import Sportshall from "../assets/sportshall.jpg";
import Homeroom from "../assets/ora-de-dirigentie.jpg";
import MobilePhone from "../assets/mobile-phone.jpg";
import DeskGraffiti from "../assets/desk-graffiti.jpg";
import CoffeeShop from "../assets/coffeeshop.jpg";
import CheatSheet from "../assets/cheat-sheet.jpg";
import GradeBook from "../assets/catalog-(class-register).jpg";

export const artefacts = [
  {
    id: 0,
    agentId: "asst_DJbwag3QJKemZ6LUKJAnKceS",
    name: "Principal",
    icon: Principal,
    url: "principal",
  },
  {
    id: 1,
    agentId: "asst_Gq5h2ko28qebTUvnLkTQnXLc",
    name: "Teacher",
    icon: Teacher,
    url: "teacher",
  },
  {
    id: 2,
    agentId:"asst_UgTkRDfbuD5IO0pMadYqNvsE",
    name: "Colegi",
    icon: Schoolmate,
    url: "colegi",
  },
  {
    id: 3,
    agentId:"asst_z3UP76eJVdq4VpRg4sWEmvw4",
    name: "Teste",
    icon: Test,
    url: "teste",
  },
  {
    id: 4,
    name: "Notebook",
    agentId:"asst_oXREPBU5unUV47pOGEQFfHqV",
    icon: Notebook,
    url: "notebook",
  },
  {
    id: 5,
    name: "Map",
    agentId:"asst_mhFD4EX4gWIq3s11BXtJ9hQ3",
    icon: Map,
    url: "map",
  },
  {
    id: 6,
    name: "Mobile Phone",
    agentId:"asst_odiht5dyCQYocTQSXleFQWep",
    icon: MobilePhone,
    url: "mobilephone",
  },
  {
    id: 7,
    agentId: "asst_LmPgzZbHjMTNvJ0bgaipIC2U",
    name: "Coffee Shop",
    icon: CoffeeShop,
    url: "coffeeshop",
  },
  {
    id: 8,
    agentId: "asst_2TLWQk9QiNTGeSTCvtmFuCPn",
    name: "Sports Hall",
    icon: Sportshall,
    url: "sportshall",
  },
  {
    id: 9,
    agentId: "asst_7tx5Dp21fUMBlpjOHmC1VFHa",
    name: "Lapte & Corn",
    icon: BreadMilk,
    url: "laptecorn",
  },
  {
    id: 10,
    agentId: "asst_IO0SWpIZ0NGG2rIzqTUWLUTH",
    name: "Catalog",
    icon: GradeBook,
    url: "catalog",
  },
  {
    id: 11,
    agentId: "asst_UbBqtsID2Mx9gzZBpJGEm1RH",
    name: "Fituica",
    icon: CheatSheet,
    url: "fituica",
  },
  {
    id: 12,
    agentId: "asst_C9ZRn0SE0tBiqd165JpTUlgG",
    name: "La Tabla!",
    icon: Whiteboard,
    url: "latabla",
  },
  {
    id: 13,
    agentId: "asst_XvX7xrtZO1eqEtihgiRmz66F",
    name: "Textbook",
    icon: Textbooks,
    url: "textbook",
  },
  {
    id: 14,
    agentId: "asst_hnmRYvb1snOg7bxgjsNUpM5m",
    name: "Ora de Dirigentie",
    icon: Homeroom,
    url: "oradedirigentie",
  },
  {
    id: 15,
    agentId: "asst_hVRsPa8auFfUJXhQ8VI6nOyd",
    name: "Graffiti",
    icon: DeskGraffiti,
    url: "graffiti",
  },
  {
    id: 16,
    agentId: "asst_1rDOPq7UDcXs0KXnQ4dLkdNH",
    name: "School Bell",
    icon: Schoolbell,
    url: "schoolbell",
  },
  {
    id: 17,
    agentId: "asst_8xe4zgjKQ4UFwBSS8KFPqW0M",
    name: "Playground",
    icon: Playground,
    url: "playground",
  },
  {
    id: 18,
    agentId: "asst_MH2dCrFnDovAJrSmNOb7UEjt",
    name: "Avizier",
    icon: BulletinBoard,
    url: "avizier",
  },
  {
    id: 19,
    agentId: "asst_IKzHb3C1jULE1s0eJEGxZJY8",
    name: "Bacalaureat",
    icon: Baccalaureate,
    url: "bacalaureat",
  },
];
