import {
  FC,
  FormEvent,
  useEffect,
  useRef,
  useState,
} from "react";

//Import components:
import {
  Box,
  IconButton,
  Input,
  SvgIcon,
  Typography,
} from "@mui/material";
import { ChatBubble } from "../../components/OnboardingComponents/ChatBubble";

//Import assets:
import { ReactComponent as SendIcon } from "../../assets/send-icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/close-icon.svg";

import MicNoneIcon from "@mui/icons-material/MicNone";

//Import utils:
import { artefacts } from "../../utils/artefacts";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LangModal } from "../../components/LangModal";

type Props = {
  className?: string;
};

type Chat = {
  id: string;
  language: string;
  conversation_started: string;
};
type Message = {
  isSender: boolean;
  message: string;
  time: string;
};
interface ScrollableListProps {
  items: string[]; // Replace with your object type
}

export const StudentChatroom: FC<Props> = (props) => {
  const { className } = props;

  const artefactsList = artefacts;
  const { state } = useLocation();
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation("translation");

  const artefact = artefactsList.find(
    (artefact) => artefact.agentId === location.pathname.split("/")[2],
  );

  const chatContainerRef = useRef<HTMLDivElement>(null);

  const [chat, setChat] = useState<Chat>();
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState("");
  const [artefactMessage, setArtefactMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [agentLanguage, setAgentLanguage] = useState("");
  const [isEmbedded, setIsEmbedded] = useState(false);

  //For language modal
  const [open, setOpen] = useState(true);
  const handleClose = () => setOpen(false);

  const formatAMPM = (date: Date) => {
    var hours = date.getHours();
    var minutesInt = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutes = minutesInt < 10 ? "0" + minutesInt : minutesInt;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };

  const createChat = async () => {
    if (agentLanguage === "") return;
    try {
      setIsLoading(true);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          agent_id: artefact?.agentId,
          language: agentLanguage,
        }),
      };

      const response = await fetch(
        `https://youthcollab-be.azurewebsites.net/api/v1/chats`,
        requestOptions,
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      setChat(result);
      setMessages([
        ...messages,
        {
          isSender: false,
          message: result.conversation_starter,
          time: formatAMPM(new Date()),
        },
      ]);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const closeChat = async () => {
    navigate("/gallery", {
      state: { id: artefact?.id, artefact: artefact?.url },
    });
    try {
      const response = await fetch(
        `https://youthcollab-be.azurewebsites.net/api/v1/chats/${chat?.id}/soft`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChat = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (newMessage.trim() === "") return;
    setMessages([
      ...messages,
      { isSender: true, message: newMessage, time: formatAMPM(new Date()) },
    ]);
    setArtefactMessage("");
    setNewMessage("");

    //Agent's message
    try {
      setIsLoading(true);
      const response = await fetch(
        `https://youthcollab-be.azurewebsites.net/api/v1/chats/${chat?.id}/messages`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: "user",
            content: newMessage,
          }),
        },
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();

      setArtefactMessage(result.content);
      //setNewMessage("");
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (chatContainerRef.current)
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
  }, [messages]);

  useEffect(() => {
    if (artefactMessage !== "") {
      setMessages([
        ...messages,
        {
          isSender: false,
          message: artefactMessage,
          time: formatAMPM(new Date()),
        },
      ]);
    }
  }, [artefactMessage]);

  useEffect(() => {

    if (window.self !== window.top){
      setIsEmbedded(true);
      setAgentLanguage("en");
    }
     
    if (!chat?.id && agentLanguage !== "") {
      createChat();
      i18n.changeLanguage(agentLanguage);
    }
  }, [agentLanguage, isEmbedded]);


  //Sender is the user and Receiver is the Artefact
  return (
    <Box className={`${className}-StudentChatroom`}>
      {
        !isEmbedded && 
        <LangModal
          open={open}
          handleClose={handleClose}
          setAgentLanguage={setAgentLanguage}
      />
      }
      <div className="StudentChatroom__header">
        <Typography className="StudentChatroom__header--text">
          {t("greeting")}{" "}
          {t(`artefacts.${artefactsList[artefact?.id ?? 0].agentId}.name`)}
        </Typography>
        {
        !isEmbedded &&
        <IconButton
          className="StudentChatroom__header--button"
          onClick={closeChat}
        >
          <CloseIcon />
        </IconButton>
        }
      </div>

      <Box className="StudentChatroom__container" ref={chatContainerRef}>
        {messages.map((message) => (
          <ChatBubble
            message={message.message}
            time={message.time}
            isSender={message.isSender}
            artefact={
              message.isSender ? "" : artefactsList[artefact?.id ?? 0].agentId
            }
          />
        ))}
        {isLoading && (
          <div className="StudentChatroom__typing">
            <div className="StudentChatroom__typing__dot"></div>
            <div className="StudentChatroom__typing__dot"></div>
            <div className="StudentChatroom__typing__dot"></div>
          </div>
        )}
      </Box>
          
        <div className="StudentChatroom__box-input">
          <form id="my-form" onSubmit={handleChat}>
            <Input
              className="StudentChatroom__textfield"
              value={newMessage}
              disableUnderline
              onChange={(event) => setNewMessage(event?.target.value)}
              // endAdornment={
              //   <IconButton size="small" aria-label="toggle password visibility">
              //     <MicNoneIcon style={{ color: "#48484A" }} />
              //   </IconButton>
              // }
            />
          </form>
          <IconButton
              type="submit"
              className="StudentChatroom__send-button"
              disabled={isLoading}
              tabIndex={0}
              form="my-form"
            >
              <SvgIcon>
                <SendIcon />
              </SvgIcon>
            </IconButton>
        </div>
    </Box>
  );
};
