import { FC, useState } from "react";

//Import components:
import {
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  SvgIcon,
  Typography,
} from "@mui/material";
import MobileStepper from "@mui/material/MobileStepper";

//Import assets:
import { ReactComponent as ArrowRight } from "../../assets/arrow_right.svg";
import { ReactComponent as ArrowLeft } from "../../assets/arrow_left.svg";
import { ReactComponent as HomeIcon } from "../../assets/home-icon.svg";

//Import utils:
import { artefacts } from "../../utils/artefacts";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

type Props = {
  className?: string;
};

export const ArtefactInterface: FC<Props> = (props) => {
  const { className } = props;

  const artefactsList = artefacts;
  const { state } = useLocation();
  const navigate = useNavigate();

  const [t, i18n] = useTranslation("translation");

  const [language, setLanguage] = useState(
    t("langChange.select") === "Selectează limba" ? "RO" : "EN",
  );

  //menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeLanguage = (lang: string) => {
    if (lang === "ro") {
      i18n.changeLanguage("ro");
      setLanguage("RO");
    } else if (lang === "en") {
      setLanguage("EN");
      i18n.changeLanguage("en");
    }
  };

  const [activeStep, setActiveStep] = useState((state?.id as number) ?? 0);

  const handleNext = () => {
    if (activeStep === artefactsList.length - 1) setActiveStep(0);
    else setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 0)
     setActiveStep(artefactsList.length - 1);
    else
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleGoToChatroom = (id: number) => {
    const artefact = artefactsList.find((i) => i.id === id);
    navigate(`/studentchatroom/${artefact?.agentId}`, {
      state: { id: artefact?.id, artefact: artefact?.url },
    });
  };

  return (
    <Box
      className={`${className}-ArtefactInterface`}
      sx={{
        filter: open ? "blur(3px)" : "",
      }}
    >
      <div className="ArtefactInterface__header">
        <Typography className="ArtefactInterface__header--text">
          {t("gallery")}
        </Typography>
      </div>
      <Container style={{ marginTop: "30px" }}>
        <div className="ArtefactInterface__square">
          <img
            className="ArtefactInterface__image"
            src={artefactsList[activeStep].icon}
          />
        </div>

        <MobileStepper
          className="ArtefactInterface__stepper"
          variant="dots"
          steps={12}
          position="static"
          activeStep={activeStep}
          sx={{
            background: "none",
            "& .MuiMobileStepper-dot": {
              backgroundColor: "transparent",
            },
            "& .MuiMobileStepper-dots": {
              position: "fixed",
              left: "50%",
              bottom: "20px",
              transform: "translate(-50%, -50%)",
              margin: "0 auto",
            },
            "& .MuiMobileStepper-dotActive": {
              backgroundColor: "transparent",
              width: "30px",
              borderRadius: "50px",
            },
          }}
          nextButton={
            <IconButton
              className="ArtefactInterface__button"
              onClick={handleNext}
            >
              <SvgIcon>
                <ArrowRight />
              </SvgIcon>
            </IconButton>
          }
          backButton={
            <IconButton
              className="ArtefactInterface__button"
              onClick={handleBack}
             
            >
              <SvgIcon>
                <ArrowLeft />
              </SvgIcon>
            </IconButton>
          }
        />

        <Typography className="ArtefactInterface__text">
          {t("greeting")}{" "}
          {t(`artefacts.${artefactsList[activeStep].agentId}.name`)}
        </Typography>

        <Typography className="ArtefactInterface__description">
          {t(`artefacts.${artefactsList[activeStep].agentId}.text`)}
        </Typography>

        <Button
          className="ArtefactInterface__button-chat"
          onClick={() => handleGoToChatroom(artefactsList[activeStep].id)}
        >
          <Typography className="ArtefactInterface__button-chat--text">
            {t("chat")}{" "}
          </Typography>
        </Button>
      </Container>
        
      <div
      style={{
        display:"flex",
        flexDirection:"row",
        justifyContent:"space-between",
        alignItems:"flex-end",
        width:"100%",
      }}
      >
      <IconButton
        className="ArtefactInterface__home-button"
        onClick={() => {
          navigate("/home");
        }}
      >
        <HomeIcon />
      </IconButton>
      <div className="ArtefactInterface__language">
        <Typography
          style={{ marginTop: "7px" }}
          className="ArtefactInterface__language--text"
        >
          {t("langChange.select")}
        </Typography>

        <Button
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          disableElevation
          onClick={handleClick}
          className="ArtefactInterface__language--button"
        >
          <Typography className="ArtefactInterface__language--text">
            {language}
          </Typography>
        </Button>

        <Menu
          elevation={0}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              background: "rgba(255, 255, 255, 0.25)",
              borderRadius: "8px",
              border: "1px solid #48484A",
              width: "325px",
              marginTop: "-60px",
            },
          }}
        >
          <MenuItem
            onClick={() => {
              handleChangeLanguage("en");
              handleClose();
            }}
            disableRipple
          >
            <Typography className={`${className}-ArtefactInterface--text`}>
              {t("langChange.english")}
            </Typography>
          </MenuItem>

          <MenuItem
            onClick={() => {
              handleChangeLanguage("ro");
              handleClose();
            }}
            disableRipple
          >
            <Typography className={`${className}-ArtefactInterface--text`}>
              {t("langChange.romanian")}
            </Typography>
          </MenuItem>
        </Menu>
      </div>


      </div>
     
    </Box>
  );
};
