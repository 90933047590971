import { bottomNavigationClasses, styled } from "@mui/material";
import { ArtefactChatroom } from "./ArtefactChatroom";

const Styled = styled(ArtefactChatroom)`
  &-ArtefactChatroom {
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
    background: #fdeac4;
    &--text {
      color: #48484a;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: none;
    }
    &&& {
      .ArtefactChatroom {
        &__header {
          align-items: center;
          text-align: center;
          display: flex;
          justify-content: space-between;
          border-radius: 8px;
          background: rgba(7, 4, 47, 0.1);
          box-shadow: 4px 4px 18px 0px rgba(255, 255, 255, 0.15);
          backdrop-filter: blur(66.5px);
          width: 100%;
          width: -moz-available;          /* WebKit-based browsers will ignore this. */
          width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
          width: fill-available;
          margin: 20px;
          padding: 16px;
          &--text {
            color: #48484a;
            text-align: center;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 133.333% */
            text-transform: uppercase;
            margin: 0px auto;
          }
        }
        &__button {
          background-color: transparent;
          border-radius: 8px;
          border: 1px solid black;
          color: black;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          margin-bottom: 24px;
          text-transform: none;
        }

        &__container {
          overflow-y: scroll;
          overflow-x: hidden;
          height: 75%;
          width: 80%;
          padding: 20px;
        }
        &__navigation {
          &.${bottomNavigationClasses.root} {
            background-color: #af9999;
            mix-blend-mode: hard-light;
            box-shadow: 34px 21px 16px 8px rgba(23, 195, 206, 0.22);
            width: inherit;
          }
        }

        &__navigation-action {
          margin-bottom: 6px;
          gap: 3px;
          margin-top: 2px;
        }

        &__image {
          height: 30%;
        }

        &__text {
          color: #fff;
          font-size: 32px;
          font-weight: 700;
          line-height: 44px;
          text-align: unset;
          margin-top: 40px;
        }
        &__description {
          color: #fff;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          text-align: unset;
          margin-top: 40px;
        }

        &__box-input {
          display: flex;
          flex-direction: row;
          background-color: #fdeac4;
          bottom: 0;
          width: 100vw;
          justify-content: center;
          //padding-bottom: 10px;
         // padding-top: 10px;
         // height: 5vh;
          position: fixed;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 10px;
        }

        &__language {
          /* position: absolute;
          right: 0;
          bottom: 0; */
          //margin: 20px;
          display: flex;
          justify-content: space-between;
          gap: 10px;
          background-color: #fdeac4;
          margin-right: 20px;

          &--text {
            color: #48484a;
            text-align: center;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: none;
          }

          &--button {
            display: flex;
            width: 40px;
            height: 40px;
            justify-content: center;
            align-items: center;
            border-radius: 68px;
            background: rgba(255, 255, 255, 0.1);
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          }
        }

        &__home-button {
          border-radius: 33.768px;
          background: rgba(255, 255, 255, 0.5);
          box-shadow: 0px 2.214px 9.964px 0px rgba(0, 0, 0, 0.1);
          margin-left: 20px;
          /* position: absolute;
          left: 0;
          bottom: 0; */
          //margin: 20px;
        }
      }
    }
  }
`;

export { Styled };
