import { mobileStepperClasses, styled } from "@mui/material";
import { ArtefactInterface } from "./ArtefactInterface";

const Styled = styled(ArtefactInterface)`
  &-ArtefactInterface {
    width: 100vw;
    height: 100vh;
    // display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    background: #fdeac4;
    &--text {
      color: #48484a;
      font-family: Poppins !important;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: none;
    }
    &&& {
      .ArtefactInterface {
        &__header {
          align-items: center;
          text-align: center;
          display: flex;
          justify-content: space-between;
          border-radius: 8px;
          background: rgba(7, 4, 47, 0.1);
          box-shadow: 4px 4px 18px 0px rgba(255, 255, 255, 0.15);
          backdrop-filter: blur(66.5px);
          width: 100%;
          width: -moz-available;          /* WebKit-based browsers will ignore this. */
          width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
          width: fill-available;
          margin: 20px;
          padding: 16px;
          &--text {
            color: #48484a;
            text-align: center;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 133.333% */
            text-transform: uppercase;
            margin: 0px auto;
          }
        }

        &__button {
          background-color: transparent;
          border-radius: 50px;
          margin: 20px;
        }
        &__border {
          border: #fff;
          border-radius: 50px;
          padding: 10px;
        }
        &__stepper {
          background: none !important;

          > &.${mobileStepperClasses.dot} {
            background-color: #fff;
          }
        }

        &__image {
          height: inherit;
          width: 100%;
          width: -moz-available;          /* WebKit-based browsers will ignore this. */
          width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
          width: fill-available;
          object-fit: fill;
          height: 100%;
          border-radius: 20px;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        }
        &__bubble {
          margin: 0 auto;
          display: block;
          width: 64px;
          left: 80%;
          cursor: pointer;
          margin-top: 20px;
        }
        &__square {
          margin: 0 auto;
          display: block;
          display: flex;
          width: 145px;
          height: 145px;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          border-radius: 20px;
          background: transparent;
        }
        &__text {
          color: #48484a;
          font-size: 26px;
          margin-top: -35px;
          text-align: center;
          font-family: Poppins;
          font-style: normal;
          font-weight: 600;
          line-height: 30px; /* 183.333% */
        }
        &__description {
          font-size: 15px;
          color: #48484a;
          text-align: center;
          margin-top: 20px;
          font-family: Poppins;
          font-style: normal;
          font-weight: 100;
          line-height: 20px; /* 142.857% */
        }
        &__button-chat {
          max-width: 100%;
          max-height: 100%;
          //position: absolute;
          left: 50%;
          margin-top: 45px;
          text-transform: none;
          transform: translate(-50%, -50%);
          border-radius: 25px;
          background: rgba(255, 255, 255, 0.1);

          /* Shadow */
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          display: inline-flex;
          height: 40px;
          padding: 25px 24px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          flex-shrink: 0;
          &--text {
            color: #48484a;
            text-align: center;
            font-family: Poppins;
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }

        &__home-button {
          border-radius: 33.768px;
          background: rgba(255, 255, 255, 0.5);
          box-shadow: 0px 2.214px 9.964px 0px rgba(0, 0, 0, 0.1);
          // position: absolute;
          /* left: 0;
          bottom: 0; */
          margin: 20px;
          position: fixed;
          bottom: 0;
        }

        &__language {
          /* position: absolute;
          right: 0;
          bottom: 0; */
          position: fixed;
          bottom: 0;
          right: 0;
          margin: 20px;
          display: flex;
          justify-content: space-between;
          gap: 10px;
          margin-top: 35px;
          &--text {
            color: #48484a;
            text-align: center;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }

          &--button {
            display: flex;
            width: 40px;
            height: 40px;
            justify-content: center;
            align-items: center;
            border-radius: 68px;
            background: rgba(255, 255, 255, 0.1);
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          }
        }
      }
    }
  }
`;

export { Styled };
