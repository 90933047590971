import React, { ReactElement, Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { theme } from "../assets/theme";
import { Onboarding } from "../views/Onboarding";
import { ArtefactInterface } from "../views/ArtefactInterface";
import { StudentChatroom } from "../views/StudentChatroom";
import { ArtefactChatroom } from "../views/ArtefactChatroom";
import { Home } from "../views/Home";
import { AboutUs } from "../views/AboutUs";
import { TokenAuth } from "../views/TokenAuth";
import { PrivateRoutes } from "./PrivateRoutes";

const IndexRouter: React.FC = (): ReactElement => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Suspense fallback={<p>Loading...</p>}>
          <Routes>
            <Route element={<PrivateRoutes />}>
              <Route path="/" element={<Onboarding />} />
            </Route>
            <Route path="/token" element={<TokenAuth />} />
            <Route path="/gallery" element={<ArtefactInterface />} />
            <Route path="/home" element={<Home />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route
                path="/studentchatroom/:artefact"
                element={<StudentChatroom />}
              />
              <Route path="/possibilities" element={<ArtefactChatroom />} />
          </Routes>
        </Suspense>
      </Router>
    </ThemeProvider>
  );
};

export default IndexRouter;
