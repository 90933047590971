import { FC } from "react";

//Import components:
import { Box, Button, Grid, Modal, Typography } from "@mui/material";

//Import utils:
import { useTranslation } from "react-i18next";

type Props = {
  className?: string;
  open: boolean;
  handleClose: () => void;
  setAgentLanguage: (lang: string) => void;
};

export const LangModal: FC<Props> = (props) => {
  const { className, open, handleClose, setAgentLanguage } = props;
  const { t } = useTranslation("translation");

  return (
    <Modal
      className={`${className}-LangModal`}
      open={open}
    >
      <Box className="LangModal__box">
        <Typography className="LangModal__box__text">
          {t("langChange.chat")}
        </Typography>

        <Grid
          className="LangModal__box__row"
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Button
              className="LangModal__box__button"
              onClick={() => {
                setAgentLanguage("en");
                handleClose();
              }}
            >
              <Typography className="LangModal__box__button__text">
                {t("langChange.english")}
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button
              className="LangModal__box__button"
              onClick={() => {
                setAgentLanguage("ro");
                handleClose();
              }}
            >
              <Typography className="LangModal__box__button__text">
                {t("langChange.romanian")}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
