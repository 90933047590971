import { FC, useEffect, useRef, useState } from "react";

//Import components:
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { ChatBubble } from "../../components/OnboardingComponents/ChatBubble";

//Import utils:
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReconnectingEventSource from "reconnecting-eventsource";

//Import assets:
import { ReactComponent as HomeIcon } from "../../assets/home-icon.svg";

type Props = {
  className?: string;
};

type InsightItem = {
  ro: string;
  en: string;
};

type Insight = {
  agent_id: string;
  timestamp: number;
  insight: InsightItem;
};

export const ArtefactChatroom: FC<Props> = (props) => {
  const { className } = props;

  const navigate = useNavigate();
  const [t, i18n] = useTranslation("translation");

  const [language, setLanguage] = useState(
    t("langChange.select") === "Selectează limba" ? "RO" : "EN",
  );
  const [insights, setInsights] = useState<Insight[]>([]);

  const chatContainerRef = useRef<HTMLDivElement>(null);

  const [isEmbedded, setIsEmbedded] = useState(false);

  useEffect(() => {
    if (window.self !== window.top) {
      setIsEmbedded(true);
      //setAgentLanguage("en");
    }
  }, []);

  useEffect(() => {
    // Create a WebSocket connection
    var es = new ReconnectingEventSource(
      "https://youthcollab-be.azurewebsites.net/api/insights/",
    );
    // Event listener for when the connection is opened
    es.addEventListener(
      "message",
      function (e) {
        const result = JSON.parse(e.data);
        setInsights((insights) => [...insights, result]);
      },
      false,
    );

    // Event listener for when the connection is closed
    es.addEventListener("close", (event) => {
      console.log("WebSocket connection closed:", event);
    });

    // Cleanup the WebSocket connection when the component unmounts
    return () => {
      es.close();
    };
  }, []);

  //menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeLanguage = (lang: string) => {
    if (lang === "ro") {
      i18n.changeLanguage("ro");
      setLanguage("RO");
    } else if (lang === "en") {
      setLanguage("EN");
      i18n.changeLanguage("en");
    }
  };

  const timestampToString = (time: number) => {
    return new Date(time * 1000).toLocaleString("en-Us", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  useEffect(() => {
    if (chatContainerRef.current)
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
  }, [insights]);

  const getInsights = async () => {
    const response = await fetch(
      `https://youthcollab-be.azurewebsites.net/api/v1/list-insights`,
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const result = await response.json();
    setInsights(result.reverse());
  };

  useEffect(() => {
    getInsights();
  }, []);

  return (
    <Box
      className={`${className}-ArtefactChatroom`}
      sx={{
        filter: open ? "blur(3px)" : "",
      }}
    >
      <div className="ArtefactChatroom__header">
        <Typography className="ArtefactChatroom__header--text">
          {t("possibilities")}
        </Typography>
      </div>

      <Box className="ArtefactChatroom__container" ref={chatContainerRef}>
        {insights.map((insight, index) => {
          if (!isEmbedded)
            return (
              <ChatBubble
                message={
                  language === "RO" ? insight.insight.ro : insight.insight.en
                }
                time={timestampToString(insight.timestamp)}
                artefact={insight.agent_id}
                isSender={index % 2 === 0 ? false : true}
              />
            );
          else if (insight.agent_id === "asst_Gq5h2ko28qebTUvnLkTQnXLc")
            return (
              <ChatBubble
                message={
                  language === "RO" ? insight.insight.ro : insight.insight.en
                }
                time={timestampToString(insight.timestamp)}
                artefact={insight.agent_id}
                isSender={index % 2 === 0 ? false : true}
              />
            );
        })}
      </Box>
      {!isEmbedded && (
        <div className="ArtefactChatroom__box-input">
          <IconButton
            className="ArtefactChatroom__home-button"
            onClick={() => {
              navigate("/home");
            }}
          >
            <HomeIcon />
          </IconButton>

          <div className="ArtefactChatroom__language">
            <Typography
              style={{ marginTop: "7px" }}
              className="ArtefactChatroom__language--text"
            >
              {t("langChange.select")}
            </Typography>

            <Button
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              disableElevation
              onClick={handleClick}
              className="ArtefactChatroom__language--button"
            >
              <Typography className="ArtefactChatroom__language--text">
                {language}
              </Typography>
            </Button>

            <Menu
              elevation={0}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  background: "rgba(255, 255, 255, 0.25)",
                  borderRadius: "8px",
                  border: "1px solid #48484A",
                  width: "325px",
                  marginTop: "-60px",
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  handleChangeLanguage("en");
                  handleClose();
                }}
                disableRipple
              >
                <Typography className={`${className}-ArtefactChatroom--text`}>
                  {t("langChange.english")}
                </Typography>
              </MenuItem>

              <MenuItem
                onClick={() => {
                  handleChangeLanguage("ro");
                  handleClose();
                }}
                disableRipple
              >
                <Typography className={`${className}-ArtefactChatroom--text`}>
                  {t("langChange.romanian")}
                </Typography>
              </MenuItem>
            </Menu>
          </div>
        </div>
      )}
    </Box>
  );
};
