import { styled } from "@mui/material";
import { StudentChatroom } from "./StudentChatroom";

const Styled = styled(StudentChatroom)`
  &-StudentChatroom {
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
    background: #fdeac4;
    &&& {
      .StudentChatroom {
        &__header {
          align-items: center;
          text-align: center;
          display: flex;
          justify-content: space-between;
          border-radius: 8px;
          background: rgba(7, 4, 47, 0.1);
          box-shadow: 4px 4px 18px 0px rgba(255, 255, 255, 0.15);
          backdrop-filter: blur(66.5px);
          width: 100%;
          width: -moz-available; /* WebKit-based browsers will ignore this. */
          width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
          width: fill-available;
          margin: 20px;
          padding: 16px;
          position: fixed;
          top: 0;
          z-index: 100;
          &--text {
            color: #48484a;
            text-align: center;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 133.333% */
            text-transform: uppercase;
            margin: 0px auto;
          }

          &--button {
            background-color: transparent;
            border-radius: 50px;
            border: 2px solid #c93030;
          }
        }

        &__container {
          overflow-y: scroll;
          overflow-x: hidden;
          height: 70%;
          width: 80%;
          padding: 20px;
          margin-top: 100px;
        }

        &__box-input {
          display: flex;
          flex-direction: row;
          background-color: #fdeac4;
          bottom: 0;
          width: 100vw;
          justify-content: center;
          margin-bottom: 20px;
          height: 5vh;
          position: fixed;
          align-items: center;

        }

        &__textfield {
          border-radius: 100px;
          background: #fff;
          padding: 12px 10px 12px 12px;
          border: 1px solid white;
          box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.1);
          max-height: 60px;
          margin-right: 18px;
          width: 70vw;
        }

        &__send-button {
          padding: 8.429px;
          justify-content: center;
          align-items: center;
          border-radius: 70.238px;
          background: #fff;
          box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.1);
          /* min-width: 40px;
          min-height: 40px; */
          align-self: center;
          :disabled {
            opacity: 0.4;
          }
        }

        &__typing {
          width: 3em;
          height: 0.7em;
          position: relative;
          padding: 10px;
          margin-left: 5px;
          background: #e6e6e6;
          border-radius: 20px;
          &__dot {
            float: left;
            width: 8px;
            height: 8px;
            margin: 0 4px;
            background: #8d8c91;
            border-radius: 50%;
            opacity: 0;
            animation: loadingFade 1s infinite;
          }

          &__dot:nth-child(1) {
            animation-delay: 0s;
          }

          &__dot:nth-child(2) {
            animation-delay: 0.2s;
          }

          &__dot:nth-child(3) {
            animation-delay: 0.4s;
          }
        }

        @keyframes loadingFade {
          0% {
            opacity: 0;
          }
          50% {
            opacity: 0.8;
          }
          100% {
            opacity: 0;
          }
        }
      }
    }
  }
`;

export { Styled };
