import { Navigate, Outlet } from "react-router-dom";
import { useGlobalContext } from "../context/context";
import { useEffect, useState } from "react";
export const PrivateRoutes = () => {
  const { globalAuthVariable } = useGlobalContext();

  return globalAuthVariable ? (
    <Outlet />
  ) : (
    <Navigate to="/token" />
  );
};
