// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import common_en from "./en/translation.json";
import common_ro from "./ro/translation.json";

i18n
  .use(initReactI18next)
  .init({
    resources: {
      // Add translations for each language here
      en: {
        translation: common_en
      },
      ro: {
        translation: common_ro
      }
      // Add more languages as needed
    },
    lng: 'en', // Default language
    fallbackLng: 'en', // Fallback language if the translation is missing
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  });

export default i18n;
