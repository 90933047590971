import { styled } from "@mui/material";
import { SecondStep } from "./SecondStep";

const Styled = styled(SecondStep)`
  &-SecondStep {
    display: flex;
    flex-direction: column;
    &&& {
      .SecondStep {
        &__title {
          color: #48484A;
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: 44px;
          margin-top: 35px;         
          margin-bottom: 25px;
          text-align: center;
        }

        &__text {
          color: #48484A;
          font-size: 16px;
          font-style: normal;
          font-family: Poppins;
          font-weight: 500;
          line-height: 24px;
          text-align: center;
          margin-bottom: 60px;
        }
      }
    }
  }
`;

export { Styled };
