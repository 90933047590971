import { FC } from "react";

//Import components:
import { Typography } from "@mui/material";

//Import assets:
import { ReactComponent as PersonIcon } from "../../../assets/person-icon.svg";

//Import utils:
import { artefacts } from "../../../utils/artefacts";
import { useTranslation } from "react-i18next";

type Props = {
  className?: string;
  message: string;
  time: string;
  isSender?: boolean;
  artefact?: string;
};

export const ChatBubble: FC<Props> = (props) => {
  const { className, message, time, isSender, artefact } = props;
  const { t } = useTranslation("translation");
  const artefactsList = artefacts;
  return (
    <div className={`${className}-ChatBubble`}>
      <Typography
        style={{
          textAlign: isSender ? "right" : "left",
          marginLeft: artefact ? "80px" : "40px",
          marginRight: artefact ? "80px" : "40px",
        }}
        className="ChatBubble__time"
      >
        {time}
      </Typography>
      <div
        style={{
          flexDirection: "row",
          display: "flex",
        }}
      >
        {/* {isSender === false && !artefact && (
          <div style={{ display: "flex" }}>
            <div className="ChatBubble__receiver-icon">
              <RobotIcon />
            </div>
          </div>
        )} */}
        {isSender === false && (
          <div className="ChatBubble__column">
            <div className="ChatBubble__artefact-icon">
              <img
                className="ChatBubble__artefact-icon__image"
                src={artefactsList.find((i) => i.agentId === artefact)?.icon}
              />
            </div>
            <Typography className="ChatBubble__artefact-text">
              {t(`artefacts.${artefact}.name`)}
            </Typography>
          </div>
        )}

        <div
          className={`ChatBubble__chat ChatBubble__chat${
            isSender ? "__sender" : "__receiver"
          }`}
          style={{
            alignSelf: artefact && isSender ? "self-start" : "",
          }}
        >
          <Typography
            className="ChatBubble__message"
            style={{
              color: isSender ? "#565656" : "#48484A",
            }}
          >
            {message}
          </Typography>
        </div>
        {isSender && !artefact && (
          <div style={{ display: "flex" }}>
            <div className="ChatBubble__sender-icon">
              <PersonIcon />
            </div>
          </div>
        )}
        {isSender && artefact && (
          <div className="ChatBubble__column">
            <div
              className="ChatBubble__artefact-icon"
              style={{
                marginLeft: "8px",
              }}
            >
              <img
                className="ChatBubble__artefact-icon__image"
                src={artefactsList.find((i) => i.agentId === artefact)?.icon}
              />
            </div>
            <Typography className="ChatBubble__artefact-text-variation">
              {t(`artefacts.${artefact}.name`)}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};
