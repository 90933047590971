import { mobileStepperClasses, styled } from "@mui/material";
import { Onboarding } from "./Onboarding";

const Styled = styled(Onboarding)`
  &-Onboarding {
    width: 100vw;
    height: 100vh;
    background: #fdeac4;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    &--text {
      color: #48484a;
      font-family: Poppins !important;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: none;
    }

    &&& {
      .Onboarding {
        &__button {
          max-width: 100%;
          max-height: 100%;
          position: absolute;
          left: 50%;
          margin-top: 35px;
          text-transform: none;
          transform: translate(-50%, -50%);

          border-radius: 25px;
          background: rgba(255, 255, 255, 0.1);

          /* Shadow */
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          display: inline-flex;
          height: 40px;
          padding: 16px 24px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          flex-shrink: 0;
          &--text {
            color: #48484a;
            text-align: center;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }
        &__stepper {
          background: none !important;
          > &.${mobileStepperClasses.dot} {
            background-color: #fff;
          }
        }
        &__title {
          color: #48484a;
          align-self: center;
          font-family: Nunito;
          font-size: 48px;
          font-style: normal;
          font-weight: 700;
          line-height: 44px;
          text-align: center;
        }

        &__language {
          position: absolute;
          right: 0;
          bottom: 0;
          margin: 20px;
          display: flex;
          justify-content: space-between;
          gap: 10px;
          margin-top: 35px;
          &--text {
            color: #48484a;
            text-align: center;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }

          &--button {
            display: flex;
            width: 40px;
            height: 40px;
            justify-content: center;
            align-items: center;
            border-radius: 68px;
            background: rgba(255, 255, 255, 0.1);
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          }
        }
      }
    }
  }
`;

export { Styled };
